import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';

export const ENDPOINT_GET_USER_ACCOUNTS = `${backendBaseUrl}/bcm/user/businesses`;

export type BcmAccountListItemType = {
  id: string;
  name: string;
  dba?: string;
  bcmStatus: string;
  hasScorePulledAtLeastOnce: boolean;
  taxId?: string;
  address: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
  };
  contact?: {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    position?: string;
  };
};

export type UserAccountsResponseType = {
  message: string;
  businesses: BcmAccountListItemType[];
};

export const getUserAccounts = () =>
  useAxios(
    {
      url: ENDPOINT_GET_USER_ACCOUNTS,
      withCredentials: true,
      method: 'GET',
    },
    { manual: true }
  );
