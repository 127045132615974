import './AuthMenu.scss';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { UserAccountNav } from '@components/shadcn/components/theme-new/user-account-nav';
import { RootState } from '@store/reducers';
import { Button } from 'shared-components';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { STEP_BCM_HOMEPAGE, STEP_HOMEPAGE } from '@components/ApplyWizard/ApplyWizardSteps';
import { formValueSelector } from 'redux-form';
import { isSyntheticTest } from '@utils/platformBasedInfo';

// const EndToEndLogin = () => {
//   const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
//   const { currentPath } = useApplyWizardContext();
//   const history = useHistory();
//   const handleSignIn = () => {
//     loginWithRedirect({
//       appState: {
//         returnTo: '/app/verify/login',
//       },
//       authorizationParams: {
//         login_hint: 'mateusz+e2e@tento.co',
//       },
//     });
//   };
//   return (
//     <Button
//       variant="text"
//       onClick={() => handleSignIn()}
//       className={`uppercase font-condensed transition-all duration-500 ease-in-out ${
//         currentPath === STEP_HOMEPAGE || currentPath === STEP_BCM_HOMEPAGE ? 'text-[#ffffff]' : 'text-[#000000]'
//       }`}
//     >
//       [E2E] Log in
//     </Button>
//   );
// };

const AuthMenu = ({
  user,
  owner_1_first,
  owner_1_last,
  owner_1_mobile,
  ownerEmail,
}: {
  user: RootState['user'];
  owner_1_first?: string;
  owner_1_last?: string;
  owner_1_mobile?: string;
  ownerEmail?: string;
}) => {
  let location = useLocation();
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
  const handleSignOut = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  const handleSignIn = () => {
    (async () => {
      if (!isSyntheticTest) {
        analytics.track('Apply Form - Login Request', {
          type: 'Header click',
        });
        // analytics.track('Apply Form - Sign In Request', {}); // Removed as agreed with Vic
        // analytics.track('Apply Form - Verify Request', {}); // Removed as agreed with Vic
      }
    })();
    loginWithRedirect({
      appState: {
        returnTo: '/app/verify/login',
      },
      authorizationParams: {
        connection: 'email',
        login_hint: ownerEmail,
        ...(owner_1_first
          ? {
              'user[given_name]': owner_1_first,
            }
          : {}),
        ...(owner_1_last
          ? {
              'user[family_name]': owner_1_last,
            }
          : {}),
        ...(owner_1_mobile
          ? {
              'user[phone_number]': owner_1_mobile,
            }
          : {}),
      },
    });
  };
  const { currentPath } = useApplyWizardContext();

  return (
    <>
      {isAuthenticated && !isLoading && (
        <div className="min-w-[80px] flex items-center justify-end">
          <UserAccountNav user={user} handleSignOut={handleSignOut} />
        </div>
      )}
      {!isAuthenticated && !isLoading && (
        // <>
        <Button
          variant="text"
          onClick={() => handleSignIn()}
          className={`uppercase font-condensed transition-all duration-500 ease-in-out ${
            currentPath === STEP_HOMEPAGE || currentPath === STEP_BCM_HOMEPAGE ? 'text-[#ffffff]' : 'text-[#000000]'
          }`}
        >
          Log in
        </Button>
        //  <Auth0Provider
        //   onRedirectCallback={(appState) => {
        //     if (appState && appState.returnTo) {
        //       history.push({
        //         pathname: appState.returnTo,
        //         state: appState,
        //       });
        //     }
        //   }}
        //   skipRedirectCallback={false}
        //   domain={'auth-dev.tento.co'}
        //   clientId={'KkhMDWKnAWwfJ1I0nbzPWvJ7AgMTffbY'}
        //   authorizationParams={{
        //     redirect_uri: window.location.origin,
        //     audience: encodeURI('https://backend.tento.co'),
        //   }}
        // >
        //   <EndToEndLogin />
        // </Auth0Provider>
        // </>
      )}
      {isLoading && <div className="min-w-[80px]" />}
    </>
  );
};

const appSelector = formValueSelector('application');
const AuthMenuConnected = connect((state: RootState) => {
  return {
    user: state.user,
    ownerEmail: appSelector(state, 'owner_1_email'),
    owner_1_first: appSelector(state, 'owner_1_first'),
    owner_1_last: appSelector(state, 'owner_1_last'),
    owner_1_mobile: appSelector(state, 'owner_1_mobile'),
  };
})(AuthMenu);

export default withRouter(AuthMenuConnected as any);
