import React, { Component, useState } from 'react';
import { withStyles } from '@material-ui/core';
import TagManager from 'react-gtm-module';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { words, currency, currencyDecimal } from '_fields/normalizers';
import { estPaymentByApr } from '@utils/paymentCalculations';
import { connect } from 'react-redux';
import { FormPageTopPart } from '../FormPageTopPart/FormPageTopPart.tsx';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Stack } from '@components/Stack/Stack';
import { Button, Stripe } from 'shared-components';

const styles = {
  paper: {
    padding: '20px',
  },
  popover: {
    pointerEvents: 'none',
  },
  typography: {
    padding: '25px',
    width: '320px',
    fontSize: '12px',
    textAlign: 'justify',
    position: 'relative',
  },
};

const OffersLoaded = ({ contactId, classes, vendor_name, offers }) => {
  const [openedPopoverId, setOpenedPopoverId] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, popoverId) => {
    setOpenedPopoverId(popoverId);
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  const dataLayer = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'offer-clicked',
        contact_id: contactId,
      },
    });
  };

  return (
    <React.Fragment>
      <Stack gap={ELEMENTS_GAP}>
        <div className="max-w-[560px] mx-auto">
          <FormPageTopPart
            headingText="Loan Options"
            subHeadingText={`Congratulations! We have matched you with the following personal loan offers...`}
          />
        </div>
        <div className="offers-disclosure-wrap group slideWidth">
          <div className="!text-center disclosure-title" onClick={(e) => handleClick(e, 'disclosure-popover')}>
            Advertiser Disclosure
          </div>
          <div className="!text-center disclosure-title" onClick={(e) => handleClick(e, 'addlinfo-popover')}>
            Additional Info
          </div>
        </div>

        <Stripe variant="grey" withDot={false}>
          Offer order is based on conversion probability, compensation from our partners, and other factors.
        </Stripe>
        <div className="offers-rate-table slideWidth">
          {offers.map((offer, idx) => {
            const payment = estPaymentByApr(offer.maxAmount, offer.termLength, offer.minApr);
            return (
              <div className="offer-box" key={offer.uuid}>
                <div className="flex justify-center offer-top align-center">
                  <img className="lender-logo-show" src={offer.originator.images[0].url} alt={offer.originator.name} />
                </div>

                <div className="flex justify-center offer-top align-center">
                  <a href={offer.url} target="_blank" onClick={dataLayer}>
                    <Button variant="primary-white">Continue</Button>
                  </a>
                </div>

                <div className="details-wrap">
                  <div className="offer-details odbr">
                    <strong>{currency(offer.maxAmount)}</strong>
                    <br />
                    <span>Loan Amount</span>
                  </div>

                  <div className="offer-details">
                    <strong>
                      {offer.termLength} {offer.termUnit}s
                    </strong>
                    <br />
                    <span>Term of Loan</span>
                  </div>

                  <div className="offer-details odbr odbt">
                    <strong>{offer.minApr}%</strong>
                    <br />
                    <span>{words(offer.aprType)} APR</span>
                  </div>

                  <div className="offer-details odbt">
                    <strong>{currencyDecimal(payment)}</strong>
                    <br />
                    <span>Est. Monthly Payment</span>
                  </div>
                </div>
                <div className="offer-disclaimer mt-[10px]" onClick={(e) => handleClick(e, offer.uuid)}>
                  Disclaimer & more info
                </div>
                <div className="text-[18px] my-[10px]">
                  {/* {JSON.stringify(offer)} */}
                  <strong>NMLS #1475872</strong>
                </div>
                <Popover
                  id={offer.uuid}
                  open={openedPopoverId === offer.uuid}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className={classes.typography}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: offer.originator.disclaimer,
                      }}
                    />
                    <CloseIcon className="close-popover" onClick={handleClose} />
                  </Typography>
                </Popover>
              </div>
            );
          })}
        </div>
      </Stack>
      <a className="offer-disclaimer mt-[10px]" href="https://www.engine.tech/resources/legal" target="_blank">
        Engine by Money Lion Disclosures
      </a>

      <Popover
        id="disclosure-popover"
        open={openedPopoverId === 'disclosure-popover'}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography className={classes.typography}>
          The offers for financial products presented on our platform are from companies with which Engine by MoneyLion,
          Tento X Inc, and their partners receive compensation. This compensation may influence the selection,
          appearance, and order of the offers displayed. However, no fee is charged to you for requesting offers for
          financial products on our platform, and the compensation also facilitates the provision of certain services at
          no charge to you. Please note that the offers presented do not include all available products or financial
          service providers. Engine by MoneyLion and Tento X Inc do not control, endorse, or guarantee any terms of
          service for third-party linked sites and assume no responsibility for your use of such sites.
          <CloseIcon className="close-popover" onClick={handleClose} />
        </Typography>
      </Popover>

      <Popover
        id="addlinfo-popover"
        open={openedPopoverId === 'addlinfo-popover'}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography className={classes.typography}>
          <strong>Representative Example:</strong> If you borrow $5,000 on a 36 month repayment term and at a 10% APR,
          the monthly repayment will be $161.34. Total repayment will be $5,808.24. Total interest paid will be $808.24.
          <br />
          <strong>APR Disclosure:</strong> The Annual Percentage Rate is the rate at which your loan accrues interest.
          It is based upon the amount of your loan, the cost of the loan, term of the loan, repayment amounts and timing
          of payments and payoff. By law, the lender or lending partner must show you the APR before you enter into the
          loan. States have laws that may limit the APR that the lender or lending partner can charge you. Rates will
          vary based on your credit worthiness, loan size, amongst other variables, with the lowest rates available to
          customers with excellent credit. Minimum and maximum loan amounts and APRs may vary according to state law and
          lender or lending partner. We recommend that you read the lender's and/or lending partner's personal terms and
          conditions in full before proceeding for a personal loan.
          <CloseIcon className="close-popover" onClick={handleClose} />
        </Typography>
      </Popover>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    vendor_name: state.brand?.data?.vendor_name,
  };
};
let OffersLoadedWithRedux = connect(mapStateToProps)(OffersLoaded);

export const OffersLoadedContainer = withStyles(styles)(OffersLoadedWithRedux);

export default OffersLoadedContainer;
