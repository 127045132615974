import queryString from 'query-string';
import { emailAddress, fedTaxId, phoneNumber, required } from './components/_fields/validation';
import { change } from 'redux-form';
import { changePriceToNumber } from './utils/changePriceToNumber';
import { estPaymentByApr } from '@utils/paymentCalculations';
import { setBrandRoutes, setHiddenRoutes } from './redux/actions';
import { normalizePhoneNumber } from './utils/normalizePhoneNumber';
import { apiService } from './services/api.service';
import { backendBaseUrl } from './utils/platformBasedInfo';

const buildOwners = (formValues) => {
  let owners = [];

  Object.keys(formValues).map((key) => {
    if (key.toString().match(/owner(.*)/)) {
      const match = key.match(/owner_(\d+)_/);
      const ownerNumber = match[1];

      const isAlreadyAdded = owners.some((owner) => owner.idx === ownerNumber);
      if (isAlreadyAdded) {
        return;
      }

      owners = [
        ...owners,
        {
          idx: ownerNumber,
          first: formValues[`owner_${ownerNumber}_first`],
          last: formValues[`owner_${ownerNumber}_last`],
          mobile: formValues[`owner_${ownerNumber}_mobile`],
          email: formValues[`owner_${ownerNumber}_email`],
          title: formValues[`owner_${ownerNumber}_title`],
          ownership: Number(formValues[`owner_${ownerNumber}_ownership`]),
          ssn: formValues[`owner_${ownerNumber}_ssn`],
          dob: formValues[`owner_${ownerNumber}_dob`],
          street: formValues[`owner_${ownerNumber}_street`],
          city: formValues[`owner_${ownerNumber}_city`],
          state: formValues[`owner_${ownerNumber}_state`],
          zip: formValues[`owner_${ownerNumber}_zip`],
          suite: formValues[`owner_${ownerNumber}_suite`],
        },
      ];
    }
  });

  return owners;
};

const validateOwner = (owner) => {
  if (
    required(owner.first) ||
    required(owner.last) ||
    required(owner.mobile) ||
    phoneNumber(owner.mobile) ||
    required(owner.email) ||
    emailAddress(owner.email) ||
    required(owner.title) ||
    required(owner.ownership) ||
    required(owner.ssn) ||
    required(owner.dob) ||
    required(owner.street) ||
    required(owner.city) ||
    required(owner.state) ||
    required(owner.zip) ||
    !states.includes(owner.state)
  ) {
    console.log(
      'INVALID OWNER:::: ',
      owner,
      required(owner.first),
      required(owner.last),
      required(owner.mobile),
      phoneNumber(owner.mobile),
      required(owner.email),
      emailAddress(owner.email),
      required(owner.title),
      required(owner.ownership),
      required(owner.ssn),
      required(owner.dob),
      required(owner.street),
      required(owner.city),
      required(owner.state),
      required(owner.zip),
      !states.includes(owner.state)
    );
    return false;
  } else {
    return true;
  }
};

const removeParam = (param, object) => {
  const { [param]: _, ...rest } = object;
  return rest;
};

export const removeFormParams = (params, paramsToBeRemoved, history) => {
  if (Object.keys(paramsToBeRemoved).length > 0) {
    const restParams = paramsToBeRemoved.reduce(
      (acc, paramName) => {
        return removeParam(paramName, acc);
      },
      { ...params }
    );

    const newUrlParams = queryString.stringify(restParams);
    const newUrl = `${location.pathname}?${newUrlParams}`;
    history.replace(newUrl);
  }
};

const fillOtherOwnerInfo = (params, paramsToBeRemoved, ownerNumber, dispatch) => {
  const ownerKeys = [
    'first',
    'last',
    'mobile',
    'email',
    'title',
    'ownership',
    'dob',
    'ssn',
    'street',
    'city',
    'zip',
    'state',
    'suite',
  ];

  for (const key of ownerKeys) {
    const ownerKey = `owner_${ownerNumber}_${key}`;

    if (ownerKey in params && params[ownerKey]) {
      const value = key === 'mobile' ? normalizePhoneNumber(params[ownerKey]) : params[ownerKey];
      dispatch(change('application', ownerKey, value));
      paramsToBeRemoved.push(ownerKey);
    }
  }
};

export const prefillSteps = async (params, dispatch, config, history) => {
  const {
    price,
    loan_amount,
    loan_terms,
    loan_type,
    title,
    equipment_description,

    business_name,
    owner_1_first,
    first_name,
    owner_1_last,
    last_name,
    owner_1_mobile,
    phone,
    owner_1_email,
    email,
    consent,

    business_years,
    year,
    business_revenue,
    business_phone,
    business_street,
    address,
    business_city,
    city,
    business_zip,
    zip_code,
    business_state,
    state,
    business_suite,
    naics_code,

    business_structure,
    business_ein,
    business_website,
    owner_1_title,
    owner_1_ownership,
    owner_1_dob,
    owner_1_ssn_masked,
    owner_1_ssn,
    owner_1_street,
    owner_1_city,
    owner_1_zip,
    owner_1_state,
    owner_1_suite,
    home_based,
  } = params;

  const paramsToBeRemoved = [];

  if (price || loan_amount) {
    const amount = loan_amount || price;
    dispatch(change('application', 'loan_amount', changePriceToNumber(amount)));
    price && paramsToBeRemoved.push('price');
    loan_amount && paramsToBeRemoved.push('loan_amount');
  }

  if (loan_terms) {
    dispatch(change('application', 'loan_terms', loan_terms));
    paramsToBeRemoved.push('loan_terms');
  }

  if (loan_type) {
    dispatch(change('application', 'loan_type', [loan_type]));
    paramsToBeRemoved.push('loan_type');
  }

  if (loan_amount && loan_terms && loan_type) {
    const paymentAmount = estPaymentByApr(
      loan_amount,
      loan_terms,
      config[loan_type?.toUpperCase() === 'WC' ? 'WC' : loan_type?.toUpperCase() === 'EQ' ? 'EQ' : 'WC'].rate
    );

    dispatch(change('application', 'loan_payment', paymentAmount));
    paramsToBeRemoved.push('loan_payment');
  }

  if (title || equipment_description) {
    const description = equipment_description || title;

    dispatch(change('application', 'equipment_description', description));
    paramsToBeRemoved.push(title ? 'title' : 'equipment_description');
    title && paramsToBeRemoved.push('title');
    equipment_description && paramsToBeRemoved.push('equipment_description');
  }

  if (business_name) {
    dispatch(change('application', 'business_name', business_name));
    paramsToBeRemoved.push('business_name');
  }

  if (owner_1_first || first_name) {
    const first = owner_1_first || first_name;
    dispatch(change('application', 'owner_1_first', first));
    owner_1_first && paramsToBeRemoved.push('owner_1_first');
    first_name && paramsToBeRemoved.push('first_name');
  }

  if (owner_1_last || last_name) {
    const last = owner_1_last || last_name;

    dispatch(change('application', 'owner_1_last', last));
    paramsToBeRemoved.push(last_name ? 'last_name' : 'owner_1_last');
  }

  if (owner_1_mobile || phone) {
    const ownerPhone = owner_1_mobile || phone;
    dispatch(change('application', 'owner_1_mobile', normalizePhoneNumber(ownerPhone)));
    owner_1_mobile && paramsToBeRemoved.push('owner_1_mobile');
    phone && paramsToBeRemoved.push('phone');
  }

  if (owner_1_email || email) {
    const owner1Email = owner_1_email || email;
    dispatch(change('application', 'owner_1_email', owner1Email));
    owner_1_email && paramsToBeRemoved.push('owner_1_email');
    email && paramsToBeRemoved.push('email');
  }

  if (
    (owner_1_first || first_name) &&
    (owner_1_last || last_name) &&
    (owner_1_mobile || phone) &&
    (owner_1_email || email)
  ) {
    dispatch(change('application', 'consent', true));
    paramsToBeRemoved.push('');
  }

  if (business_years || year) {
    var tib = business_years || year;
    dispatch(change('application', 'business_years', Number(tib)));
    business_years && paramsToBeRemoved.push('business_years');
    year && paramsToBeRemoved.push('year');
  }
  if (business_revenue) {
    dispatch(change('application', 'business_revenue', business_revenue));
    paramsToBeRemoved.push('business_revenue');
  }
  if (business_phone) {
    dispatch(change('application', 'business_phone', normalizePhoneNumber(business_phone)));
    paramsToBeRemoved.push('business_phone');
  }
  if (business_street || address) {
    const street = business_street || address;
    dispatch(change('application', 'business_street', street));
    business_street && paramsToBeRemoved.push('business_street');
    address && paramsToBeRemoved.push('business_street');
  }
  if (business_city || city) {
    const businessCity = business_city || city;
    dispatch(change('application', 'business_city', businessCity));
    business_city && paramsToBeRemoved.push('business_city');
    city && paramsToBeRemoved.push('business_city');
  }
  if (business_zip || zip_code) {
    const zip = business_zip || zip_code;
    dispatch(change('application', 'business_zip', zip));
    business_zip && paramsToBeRemoved.push('business_zip');
    zip_code && paramsToBeRemoved.push('business_zip');
  }
  if (business_state || state) {
    const businessState = business_state || state;
    dispatch(change('application', 'business_state', businessState));
    business_state && paramsToBeRemoved.push('business_state');
    state && paramsToBeRemoved.push('business_state');
  }
  if (business_suite) {
    dispatch(change('application', 'business_suite', business_suite));
    paramsToBeRemoved.push('business_suite');
  }
  if (naics_code) {
    dispatch(change('application', 'naics_keyword', naics_code));
    paramsToBeRemoved.push('naics_code');
  }
  if (business_structure) {
    dispatch(change('application', 'business_structure', business_structure));
    paramsToBeRemoved.push('business_structure');
  }
  if (business_ein) {
    dispatch(change('application', 'business_ein', business_ein));
    paramsToBeRemoved.push('business_ein');
  }
  if (business_website) {
    dispatch(change('application', 'business_website', business_website));
    paramsToBeRemoved.push('business_website');
  }
  if (owner_1_title) {
    dispatch(change('application', 'owner_1_title', owner_1_title));
    paramsToBeRemoved.push('owner_1_title');
  }
  if (owner_1_ownership) {
    dispatch(change('application', 'owner_1_ownership', owner_1_ownership));
    paramsToBeRemoved.push('owner_1_ownership');
  }
  if (owner_1_dob) {
    dispatch(change('application', 'owner_1_dob', owner_1_dob));
    paramsToBeRemoved.push('owner_1_dob');
  }
  if (owner_1_ssn) {
    dispatch(change('application', 'owner_1_ssn', owner_1_ssn));
    paramsToBeRemoved.push('owner_1_ssn');
  }
  if (owner_1_street) {
    dispatch(change('application', 'owner_1_street', owner_1_street));
    paramsToBeRemoved.push('owner_1_street');
  }
  if (owner_1_city) {
    dispatch(change('application', 'owner_1_city', owner_1_city));
    paramsToBeRemoved.push('owner_1_city');
  }
  if (owner_1_zip) {
    dispatch(change('application', 'owner_1_zip', owner_1_zip));
    paramsToBeRemoved.push('owner_1_zip');
  }
  if (owner_1_state) {
    dispatch(change('application', 'owner_1_state', owner_1_state));
    paramsToBeRemoved.push('owner_1_state');
  }
  if (owner_1_suite) {
    dispatch(change('application', 'owner_1_suite', owner_1_suite));
    paramsToBeRemoved.push('owner_1_suite');
  }
  if (home_based === 'true') {
    const street = business_street || address;
    const businessCity = business_city || city;
    const zip = business_zip || zip_code;
    const businessState = business_state || state;

    dispatch(change('application', 'owner_1_street', street));
    dispatch(change('application', 'owner_1_city', businessCity));
    dispatch(change('application', 'owner_1_zip', zip));
    dispatch(change('application', 'owner_1_state', businessState));
    dispatch(change('application', 'owner_1_suite', business_suite));
  }
  let ownerNumber = 2;

  while (true) {
    const ownerKeyPrefix = `owner_${ownerNumber}`;
    const ownerExists = Object.keys(params).some((key) => key.startsWith(ownerKeyPrefix));

    if (!ownerExists) {
      break;
    }

    fillOtherOwnerInfo(params, paramsToBeRemoved, ownerNumber, dispatch);
    ownerNumber++;
  }
  await new Promise((resolve) => {
    resolve();
  });
  removeFormParams(params, paramsToBeRemoved, history);
};

export const hideSteps = async ({
  dispatch,
  stepsToHide,
  routes,
  formValues: valuesProvidedToHideFeature,
  finalInAppValues,
  user,
  callApi,
  dataLayerPush,
  vendor_name,
  loanId,
  auth0,
  history,
}) => {
  const hiddenRoutes = stepsToHide
    .filter((idToHide) => !!flowSteps.find((route) => route.id === idToHide))
    .map((idToHide) => flowSteps.find((route) => route.id === idToHide))
    .filter((routeToHide) => routeToHide && routeToHide.validate({ ...valuesProvidedToHideFeature, loanId }));

  const filteredRoutes = [...routes].filter(
    (r) =>
      !hiddenRoutes.some((hiddenRoute) =>
        (Array.isArray(hiddenRoute.path) ? hiddenRoute.path : [hiddenRoute.path]).includes(r)
      )
  );

  dispatch(setBrandRoutes(filteredRoutes));

  await Promise.all(
    hiddenRoutes.map(async (routeToHide) => {
      if (routeToHide.onHide) {
        await routeToHide.onHide(
          dispatch,
          callApi,
          dataLayerPush,
          vendor_name,
          auth0,
          filteredRoutes,
          history
        )(valuesProvidedToHideFeature, user, finalInAppValues);
      }
    })
  );

  return filteredRoutes;
};

export const flowSteps = [
  {
    id: 'amountStep',
    path: 'amount',
    validate: ({ loan_amount, loan_terms, loan_type }) => {
      // loan_payment is caluclated

      if (required(loan_amount) || required(loan_terms) || required(loan_type)) {
        return false;
      } else {
        return true;
      }
    },
  },

  {
    id: 'equipmentStep',
    path: 'equipment',
    validate: ({ equipment_description }) => {
      if (required(equipment_description)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'infoStep',
    path: 'info',
    validate: ({ business_name, owner_1_first, owner_1_last, owner_1_mobile, owner_1_email }) => {
      if (
        required(business_name) ||
        required(owner_1_first) ||
        required(owner_1_last) ||
        required(owner_1_mobile) ||
        phoneNumber(owner_1_mobile) ||
        required(owner_1_email) ||
        emailAddress(owner_1_email)
      ) {
        return false;
      } else {
        return true;
      }
    },
    onHide:
      (dispatch, callApi, dataLayerPush, vendor_name, auth0, routes, history) =>
      async (toHideValues, user, finalInAppValues) => {
        if (!auth0.isAuthenticated) {
          auth0.loginWithRedirect({
            authorizationParams: {
              connection: 'email',
              screen_hint: 'signup',
            },
            appState: {
              returnTo: '/app/verify/login',
            },
          });
        } else {
          const index = routes.findIndex((route) => route.includes('verify/login'));

          if (index !== -1) {
            history.push(`/app/${routes[index + 1]}`);
          }
        }
      },
  },

  // LOGGED IN STEPS:
  {
    id: 'businessYearsStep',
    path: 'business/years',
    validate: ({ business_years, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_years)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessRevenueStep',
    path: 'business/revenue',
    validate: ({ business_revenue, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_revenue)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessPhoneStep',
    path: 'business/phone',
    validate: ({ business_phone, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_phone) || phoneNumber(business_phone)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessAddressStep',
    path: 'business/address',
    validate: ({ business_street, business_city, business_zip, business_state, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (
        required(business_street) ||
        required(business_city) ||
        required(business_zip) ||
        required(business_state) ||
        !states.includes(business_state)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessBasedStep',
    path: 'business/based',
    validate: ({ home_based, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(home_based)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessNaicsStep',
    path: 'business/naics',
    validate: ({ naics_keyword, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(naics_keyword)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessStructureStep',
    path: 'business/structure',
    validate: ({ business_structure, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_structure)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessEinStep',
    path: 'business/ein',
    validate: ({ business_ein, loanId }) => {
      if (!loanId) {
        return false;
      }
      if (required(business_ein) || !fedTaxId(business_ein)) {
        return false;
      } else {
        return true;
      }
    },
  },
  {
    id: 'businessWebsiteStep',
    path: 'business/website',
    validate: ({ business_website, loanId }) => {
      if (!loanId) {
        return false;
      }
      return true;
    }, // OPTIONAL
  },
  {
    id: 'ownersInfoSteps',
    path: [
      'owners/:idx/info',
      'owners/:idx/ownership',
      'owners/:idx/address',
      'owners/:idx/identity',
      'owners/:idx/add',
    ],
    validate: (formValues) => {
      if (!formValues.loanId) {
        return false;
      }
      const owners = buildOwners(formValues);
      const allOwnersValid = owners.every((owner) => validateOwner(owner));
      return allOwnersValid;
    },
  },

  // {
  //     id: 'decisionProcessStep', path: 'decision/process', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // {
  //     id: 'decisionPersonalStep', path: 'decision/personal', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // {
  //     id: 'decisionOffersStep', path: 'decision/offers', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // NOT HIDABLE
  // {
  //     id: 'bankConnectStep', path: 'bank/connect', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // {
  //     id: 'bankUploadStep', path: 'bank/upload', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // {
  //     id: 'bankEmailStep', path: 'bank/email', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
  // 3 ways of connecting bank: plaid / upload /send to mail
  // {
  //   id: 'agreementTermsStep',
  //   path: 'agreement/terms',
  //   validate: ({}) => {
  //     if (required()) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  // },
  // {
  //   id: 'agreementSignStep',
  //   path: 'agreement/sign',
  //   validate: ({}) => {
  //     if (required()) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  // },
  // {
  //   id: 'agreementUploadStep',
  //   path: 'agreement/upload',
  //   validate: ({}) => {
  //     if (required()) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  // },
  // {
  //   id: 'agreementProblemStep',
  //   path: 'agreement/problem',
  //   validate: ({}) => {
  //     if (required()) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  // },
  // {
  //     id: 'doneStep', path: 'done', validate: ({ }) => {
  //         if (required()) {
  //             return false
  //         } else {
  //             return true
  //         }
  //     }
  // },
];

const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const saveHiddenDataAfterLogin = async (accountId, formValues, contact1Id, vendor_name, user) => {
  const data = {
    // product: 'EQ',
    // loanRequest: {
    //   amount: 37000,
    //   termLength: 60,
    //   termUnit: 'months',
    //   equipmentDesc: null,
    // },
    source: {
      // TODO: pass partner & brand info
      partnerId: 'partner-public-id',
      brandId: 'brand-public-id',
      // TODO: handle UTMs
      // campaign: {
      //   utmSource: 'utm_source',
      //   utmMedium: 'utm_medium',
      //   utmCampaign: 'utm_campaign',
      //   utmTerm: 'utm_term',
      //   utmContent: 'utm_content',
      // },
      customFields: {},
    },
    business: {
      id: accountId,
      // name: 'Creole Orleans Catering, LLC',
      // doingBusinessAs: null,
      entityType: formValues?.business_structure || null,
      taxIdNumber: formValues?.business_ein || null,
      naicsCode: formValues?.naics_keyword || null,
      // naicsDescription: 'test',
      annualRevenue: formValues?.business_revenue || null,
      // businessInceptionDate: '2020-01-13T00:00:00.000Z',
      phone: formValues?.business_phone || null,
      // mobile: '4049417980',
      // email: 'm.ali@creoleorleans.com',
      website: formValues?.business_website || null,
      // employees: 8,
      years: formValues?.business_years || null,
      address: {
        address1: formValues?.business_street || null,
        address2: formValues?.business_suite || null,
        city: formValues?.business_city || null,
        state: formValues?.business_state || null,
        postalCode: formValues?.business_zip || null,
      },
    },
    owners: [],
  };

  Object.keys(formValues).map((key) => {
    if (key.toString().match(/owner(.*)/)) {
      const match = key.match(/owner_(\d+)_/);
      const ownerNumber = match[1];

      const isAlreadyAdded = data.owners.some((owner) => owner.idx === ownerNumber);
      if (isAlreadyAdded) {
        return;
      }

      data.owners = [
        ...data.owners,
        {
          main: ownerNumber === '1' ? true : false,
          id: ownerNumber === '1' ? contact1Id : null,
          contactId: ownerNumber === '1' ? contact1Id : null,
          idx: ownerNumber,
          first: formValues[`owner_${ownerNumber}_first`],
          last: formValues[`owner_${ownerNumber}_last`],
          mobile: formValues[`owner_${ownerNumber}_mobile`],
          email: formValues[`owner_${ownerNumber}_email`],
          title: formValues[`owner_${ownerNumber}_title`],
          ownership: formValues[`owner_${ownerNumber}_ownership`],
          ssn: formValues[`owner_${ownerNumber}_ssn`],
          dob: formValues[`owner_${ownerNumber}_dob`],
          street: formValues[`owner_${ownerNumber}_street`],
          city: formValues[`owner_${ownerNumber}_city`],
          state: formValues[`owner_${ownerNumber}_state`],
          zip: formValues[`owner_${ownerNumber}_zip`],
          suite: formValues[`owner_${ownerNumber}_suite`],
        },
      ];
    }
  });
  data.contacts = data.owners; // TODO: remove owners when migrated to new name "contacts"

  await apiService.call('deals', data, vendor_name, user, { method: 'patch', baseUrl: backendBaseUrl });
};
